<script lang="ts" setup>
  import type * as contentful from 'contentful'
  import { useDropdownKeyboardEvents, type DropdownRefValue } from '@/components/common/CDropdown/CDropdown.composables'
  import type { TypeNavigationItemGroupSkeleton } from '@/types/contentful'
  import { definedItems } from '@/utils/contentful'

  defineProps<{
    data: contentful.Entry<TypeNavigationItemGroupSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS', string>
  }>()

  const dropdownRef = ref<DropdownRefValue>(null)
  const { setDropdownItemRef, handleItemClick, focusFirstDropdownItem, focusNextElement, focusPreviousElement } =
    useDropdownKeyboardEvents(dropdownRef)
</script>

<template>
  <CDropdown ref="dropdownRef" @keydown.down.prevent="focusFirstDropdownItem">
    <template #trigger>
      <span aria-haspopup="menu" class="nav-link dropdown-trigger">
        {{ data.fields.title }}
      </span>
    </template>

    <template #list>
      <li
        v-for="(item, index) in definedItems(data.fields.items)"
        :key="item.fields.title"
        :ref="(el) => setDropdownItemRef(el, index)"
        class="nav-dropdown-link"
        tabindex="0"
        @keydown.down.exact="focusNextElement($event, index)"
        @keydown.up.exact="focusPreviousElement($event, index)"
        @keydown.enter.exact="handleItemClick(index)"
      >
        <CLink
          :href="item.fields.href"
          :is-embedded-app-link="item.fields.target === 'App'"
          :target="item.fields.target"
          class="dropdown-link"
        >
          {{ item.fields.title }}
        </CLink>
      </li>
    </template>
  </CDropdown>
</template>

<style lang="scss" scoped>
  .dropdown-trigger {
    padding: 0 4px;

    &:hover {
      color: var(--color-base-black) !important; // Override the color from the CLink component
    }
  }

  .nav-dropdown-link {
    margin: 0;

    .dropdown-link {
      display: block;
      padding: 16px;
      color: var(--color-base-white);
    }
  }

  .nav-dropdown-link:not(:last-child) {
    border-bottom: 1px solid var(--color-secondary-green-hover);
  }

  .nav-dropdown-link:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .nav-dropdown-link:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
</style>
